import React from "react";
import {Helmet} from "react-helmet";

function Home() {
  const redirect = () => {
    if (typeof window !== "undefined") {
      var userLang = navigator.language || navigator.userLanguage;
      var res = userLang.substring(0, 2);

      if (res === "de") {
        window.location = window.location.href + "de/";
      } else {
        window.location = window.location.href + "en/";
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="8r12qqYR97x_HJ-Nadxtv-rIC55tPkJrrTR6A57hWKE" />
      </Helmet>

      {redirect()}

      <noscript>
        It looks like you have turned off JavaScript. Please select a language
        to continue:
        <a href="/en/">English</a> | <a href="/de/">German</a> |
        <a href="/fr/">Français</a> | <a href="/es/">Español</a> |
        <a href="/it/">Italiano</a> | <a href="/cs/">Čeština</a> |
        <a href="/nl/">Nederlands</a> | <a href="/pl/">polski</a> |
        <a href="/sv/">Svenska</a> | <a href="/tr/">Türkçe</a>
      </noscript>
    </>
  );
}

export default Home;
